<template>
  <div class="container">
    <div class="grid justify-content-center my-6">
      <div class="col-12 md:col-5 lg:col-5 sm:col-8 xs:col-11 comp-grid">
		<div class="flex align-items-center mb-3">
              <div v-if="!isSubPage" class="col-fixed">
                <Button
                  @click="$router.go(-1)"
                  label=""
                  icon="pi pi-arrow-left"
                  class="p-button-sm p-button-rounded px-4"
                />
              </div>
              <div class="col-12 md:col-12">
                <div class="text-2xl text-primary font-bold">Password Reset</div>
              </div>
            </div>
        <div class="card shadow-3">
          <div class="mt-3 mb-4">
            <div>
               Enter your new password and we'll handle the rest
            </div>
          </div>
          <form ref="observer" tag="form" @submit.prevent="submitForm()">
            <div class="mb-2 text-gray-400">New Password *</div>
            <div class="mb-3">
              <Password
                class="w-full"
                inputClass="w-full"
                :feedback="false"
                toggleMask
                v-model="formData.password"
                placeholder="New Password"
                :class="getErrorClass('password')"
                ></Password
              >
              <small v-if="isFieldValid('password')" class="p-error">{{
                getFieldError("password")
              }}</small>
            </div>
            <div class="mb-2 text-gray-400">Confirm New Password *</div>
            <div class="mb-4">
              <Password
                class="w-full"
                inputClass="w-full"
                :feedback="false"
                toggleMask
                v-model="formData.confirm_password"
                placeholder="Confirm New password"
                :class="getErrorClass('confirm_password')"
              />
              <small v-if="isFieldValid('confirm_password')" class="p-error">{{
                getFieldError("confirm_password")
              }}</small>
            </div>
            <div class="mt-1 text-center">
              <Button
                type="submit"
                class="w-full py-3"
                :loading="saving"
                label="Change Password"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, reactive, onMounted, toRefs } from "vue";
import { useRoute } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required, sameAs } from "@/services/validators";
import { useApp } from "@/composables/app.js";
import { useAddPage } from "@/composables/addpage.js";
import { $t } from "@/services/i18n";
const props = defineProps({
  pageName: {
    type: String,
    default: "auth",
  },
  routeName: {
    type: String,
    default: "resetpassword",
  },
  apiPath: {
    type: String,
    default: "auth/resetpassword",
  },
});
const app = useApp();
const route = useRoute();
const formDefaultValues = {
  password: "",
  confirm_password: "",
  token: route.query.token,
  email: route.query.email,
};
const formData = reactive({ ...formDefaultValues });
function beforeSubmit() {
  return true;
}
// redirect to another page
function onFormSubmited(response) {
  app.navigateTo("/index/resetpassword_completed");
}
//form validation rules
const rules = computed(() => {
  return {
    password: { required },
    confirm_password: {
      required,
      sameAs: sameAs(formData.password, "Password"),
    },
  };
});
const v$ = useVuelidate(rules, formData); // form validation
const page = useAddPage({ props, formData, v$, onFormSubmited, beforeSubmit });
const { saving } = toRefs(page.state);
//page methods
const { submitForm, getErrorClass, getFieldError, isFieldValid } = page.methods;
onMounted(() => {
  const pageTitle = "Reset Password";
  app.setPageTitle(props.routeName, pageTitle); // set browser page title
});
</script>
